
import { Component } from 'vue-property-decorator';
import GenericMixin from '@/lib/mixins/GenericMixin';
import { mixins } from 'vue-class-component';

@Component({
  components: {},
})
export default class AuthLayout extends mixins(GenericMixin) {
  currentYear = new Date().getFullYear();
}
